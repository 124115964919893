<template>
  <div class="my-info app-my-wrap">
    <div class="app-my-title">
      {{detail.title}}
    </div>
    <div class="app-my-content">
      <div class="my-info-detail-wrap">
        <div style="margin-bottom: 20px">
          <div class="my-info-detail">
            <div class="my-info-detail-label">カテゴリ</div>
            <div class="my-info-detail-value" v-if="detail.category === '0'">新規講座開講のお知らせ</div>
            <div class="my-info-detail-value" v-if="detail.category === '1'">講座内容の訂正</div>
            <div class="my-info-detail-value" v-if="detail.category === '2'">講座内容の追加</div>
            <div class="my-info-detail-value" v-if="detail.category === '3'">講座内容の削除</div>
            <!-- <el-tag v-if="scope.row.category === '1'" size="small" class="studypack-button-primary" type="primary">講座内容の訂正</el-tag>
            <el-tag v-if="scope.row.category === '2'" size="small" class="studypack-button-primary" type="primary">講座内容の追加</el-tag>
            <el-tag v-if="scope.row.category === '3'" size="small" class="studypack-button-primary" type="primary">講座内容の削除</el-tag>
            <div class="my-info-detail-value">{{detail.category}}</div> -->
          </div>
          <div class="my-info-detail">
            <div class="my-info-detail-label">日時</div>
            <div class="my-info-detail-value">{{detail.time}}</div>
          </div>
          <!-- 
          <div class="my-info-detail">
            <div class="my-info-detail-label">件名</div>
            <div class="my-info-detail-value">{{detail.title}}</div>
          </div>
          <div class="my-info-detail">
            <div class="my-info-detail-label">概要</div>
            <div style="font-size:17px;margin-bottom:10px;white-space:pre-wrap; word-wrap:break-word;line-height:1.5em;">{{detail.abstract}}</div>
          </div>
          -->
          <div class="my-info-detail">
            <div class="my-info-detail-label">内容</div>
            <div style="font-size:17px;margin-bottom:10px;white-space:pre-wrap; word-wrap:break-word;line-height:1.5em;">{{detail.content}}</div>
          </div>
        </div>
      </div>
      <el-button native-type="button" class="studypack-button-primary" type="primary" plain @click="goBack"> インフォメーション一覧に戻る </el-button>
      <br>
      <br>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      id: '',
      detail: {},
    };
  },
  created() {
    this.id = this.$route.params.id;

    request('getInformationDetail', {
      info_id: this.id,
    }).then((res) => {
      const item = res;

      this.detail = {
        id: item.infoId,
        time: dayjs(item.openStartTime).format('YYYY-MM-DD HH:mm:ss'),
        category: item.infoType,
        title: item.infoName,
        abstract: item.infoOverview,
        content: item.infoDetail,
        openStartTime: dayjs(item.openStartTime).format('YYYY-MM-DD'),
        openEndTime: dayjs(item.openEndTime).format('YYYY-MM-DD'),
      };
    });
  },
  methods: {
  // getMaterialList(data) {
  //   this.tableIsLoading = true;
  //   request('getMaterialList', data).then((res) => {
  //     this.materialList = res;
  //   }).finally(() => {
  //     this.tableIsLoading = false;
  //   });
  // },
  // pageChange() {
  //   this.getMaterialList();
  // },
  goBack() {
    this.$router.push({
      path: `/mypage/informations`,
    }).catch(() => {});
  }
  }
};
</script>

<style lang="less" scoped>
.my-info {
  .my-info-detail-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;

    .my-info-detail {
      font-size: 15px;

      .my-info-detail-label {
        padding: 8px;
        width: 180px;
        display: inline-block;
        font-weight: bold;
      }
      .my-info-detail-value {
        padding: 8px;
        display: inline-block;
      }
    }
    .my-info-desc {
      flex: 1;
      padding: 20px 8px;
      font-size: 13px;
      border-top: 1px solid #ccc;
    }
  }
}
</style>
